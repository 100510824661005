import React, { FC, useCallback, useState } from "react"
import SwiperCore, { Pagination, A11y, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import style from "styled-components"
import ReactPlayer from "react-player"

SwiperCore.use([A11y, Autoplay, Pagination])

export interface SwiperContent {
  id: string
  type: "image" | "video"
  title: string
  alt: string
  data: unknown
  ratio?: number
  extra?: unknown
}

interface VideoContainerProps {
  ratio: number
}

const VideoContainer = style.div<VideoContainerProps>`
  position: relative;
  width: 100%;
  padding: ${props => `${props.ratio * 100}% 0 0 0`};
`

const Video = style(ReactPlayer)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: -1;
`

const ICSwiper: FC<{
  swiperContents: SwiperContent[]
  centerSlides?: boolean
  slidesPerView?: number
  spaceBetween?: number
}> = ({ swiperContents, centerSlides, slidesPerView, spaceBetween }) => {
  const [activeId, setActiveId] = useState<string>("")

  const handleContentClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const clickedContentId: string = e.currentTarget.dataset.id as string

      if (clickedContentId === activeId) {
        setActiveId("")
      } else {
        setActiveId(e.currentTarget.dataset.id as string)
      }
    },
    [activeId]
  )

  return (
    <Swiper
      centeredSlides={centerSlides || false}
      slidesPerView={slidesPerView || 1}
      spaceBetween={spaceBetween || 12}
      pagination={{ type: "bullets", clickable: true }}
    >
      {swiperContents.map(({ id, type, title, alt, data, ratio }) => (
        <SwiperSlide key={id} data-id={id} onClick={handleContentClick}>
          {() => (
            <>
              {type === "image" && (
                <GatsbyImage
                  image={data as IGatsbyImageData}
                  title={title}
                  alt={alt}
                />
              )}
              {type === "video" && (
                <VideoContainer ratio={ratio as number}>
                  <Video url={data as string} playing={activeId === id} />
                </VideoContainer>
              )}
            </>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default ICSwiper
