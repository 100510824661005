import styled from "styled-components"

interface ContainerProps {
  fluid?: boolean
  padding?: string
  margin?: string
  bgColor?: string
  scrollable?: boolean
  relax?: boolean
  isHidden?: boolean
}
const StyledContainer = styled.div<ContainerProps>`
  width: ${props => (props.fluid ? "100%" : "auto")};
  background: ${props => props.bgColor || "white"};
  max-width: ${props => !props.fluid && "1200px"};
  margin: ${props => props.margin} ${props => !props.fluid && "auto"};
  padding: ${props => props.padding};
  padding: ${props => props.relax && "0.75rem"};
  overflow: ${props => props.scrollable && "scroll"};
  display: ${props => props.isHidden && "none"};
`
export default StyledContainer
