import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons"
import { Formik } from "formik"
import { Form, FormItem, Input, SubmitButton } from "formik-antd"
import { parsePhoneNumberFromString, PhoneNumber } from "libphonenumber-js"
import React, { FC, useCallback } from "react"
import * as Yup from "yup"

const ContactInfoSchema = Yup.object().shape({
  customer_name: Yup.string()
    .required("Vui lòng nhập tên")
    .min(5, "Thông tin không hợp lệ, ít nhất 5 ký tự!!!")
    .max(25, "Thông tin không hợp lệ, tối đa 25 ký tự!!!")
    .trim(),
  phone_number: Yup.string()
    .required("Vui lòng nhập số điện thoại")
    .test("is-vn-phone-number", "Số điện thoại không hợp lệ", value => {
      if (value) {
        const phoneNumber: PhoneNumber | undefined = parsePhoneNumberFromString(
          value,
          "VN"
        )
        if (phoneNumber) {
          return !!phoneNumber.isValid()
        }
      }
      return false
    }),
  email: Yup.string().email("Email không hợp lệ").trim(),
  message: Yup.string()
    .required("Vui lòng nhập tin nhắn")
    .min(20, "Tin nhắn ít nhất 20 chữ")
    .max(100, "Tin nhắn tối đa 100 chứ"),
})

const defaultValues = {
  customer_name: "",
  phone_number: "",
  email: "",
  message: "",
}

const ContactUs: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleFormSubmit = useCallback(({ values, actions }) => {}, [])

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={ContactInfoSchema}
      onSubmit={handleFormSubmit}
    >
      {() => (
        <Form layout="vertical">
          <FormItem name="customer_name" required label="Họ và Tên">
            <Input
              name="customer_name"
              placeholder="Họ và Tên"
              allowClear
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </FormItem>

          <FormItem name="phone_number" required label="Số Điện Thoại">
            <Input
              name="phone_number"
              placeholder="##########"
              allowClear
              prefix={<PhoneOutlined className="site-form-item-icon" />}
            />
          </FormItem>

          <FormItem name="email" label="Email">
            <Input
              type="text"
              name="email"
              placeholder="example@domain.com"
              allowClear
              prefix={<MailOutlined className="site-form-item-icon" />}
            />
          </FormItem>

          <FormItem name="message" required label="Tin Nhắn">
            <Input.TextArea
              name="message"
              placeholder=""
              autoSize={{ minRows: 3, maxRows: 8 }}
            />
          </FormItem>

          <SubmitButton shape="round">Gởi Tin Nhắn</SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

export default ContactUs
