/* eslint-disable import/no-extraneous-dependencies */
import { FC } from "react"
import { Grid, Typography, Row, Col, Space, Button } from "antd"
import SwiperCore, { A11y, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { PhoneOutlined } from "@ant-design/icons"
import styled from "styled-components"

import ResponsiveMasonry from "@components/ResponsiveMasonry"
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import StyledContainer from "@root/ic-ui-react/StyledContainer"
import FlexContainer from "@root/ic-ui-react/FlexContainer"
import ICSwiper, { SwiperContent } from "@root/components/Swiper"
import ContactForm from "@components/ContactForm"
import ResBanner from "@root/components/ResBanner"
import MainSlider from "@root/images/main-slider.jpg"
import Dainichi from "@root/images/dainichi.png"
import IsaKoiFarm from "@root/images/isa-koi-farm.jpg"
import Konishi from "@root/images/konishi.jpg"
import Marugen from "@root/images/marugen.jpg"
import Narita from "@root/images/narita.jpg"
import Omosako from "@root/images/omosako.jpg"
import OoyaKoiFarm from "@root/images/ooya-koi-farm.png"
import Taniguchi from "@root/images/taniguchi.jpg"
import YamamatsuKoiFarm from "@root/images/yamamatsu-koi-farm.jpg"
import IndoorPond from "@images/indoor-pond.jpg"
import IndoorKoiGarden from "@images/indoor-koi-garden.jpg"
import Pond1 from "@images/pond-01.jpg"
import Pond2 from "@images/pond-02.jpg"
import Pond3 from "@images/pond-03.jpg"
import Pond4 from "@images/pond-04.jpg"

const GridItem = styled.div`
  width: 100%;
  padding: 6px;
`

SwiperCore.use([A11y, Autoplay])

const JapaneseBrands = [
  {
    src: Marugen,
    alt: "Marugen Logo",
  },
  {
    src: IsaKoiFarm,
    alt: "Isa Koi Farm Logo",
  },
  {
    src: Konishi,
    alt: "Konishi Logo",
  },
  {
    src: Dainichi,
    alt: "Danichi Logo",
  },
  {
    src: Narita,
    alt: "Narita Logo",
  },
  {
    src: Omosako,
    alt: "Omosako Logo",
  },
  {
    src: OoyaKoiFarm,
    alt: "OoyaKoiFarm Logo",
  },
  {
    src: Taniguchi,
    alt: "Taniguchi Logo",
  },
  {
    src: YamamatsuKoiFarm,
    alt: "Yamamatsu Koi Farm Logo",
  },
]

const BestSelling: SwiperContent[] = [
  {
    id: "COhaMUwmP4I",
    type: "video",
    title: "sanke vip Sakai FF size 90 Oder trực tiếp bên japan",
    alt: "sanke vip Sakai FF size 90 Oder trực tiếp bên japan",
    data: "https://www.youtube.com/embed/COhaMUwmP4I",
    ratio: 1,
  },
  {
    id: "xYpkYHhYo7Q",
    type: "video",
    title: "Cá nisai đấu giá của KINGKOIFARM JP",
    alt: "Cá nisai đấu giá của KINGKOIFARM JP",
    data: "https://www.youtube.com/embed/xYpkYHhYo7Q",
    ratio: 1,
  },
  {
    id: "ehBYExEkRn4",
    type: "video",
    title: "Shiro trại yamasanKF nhập khẩu bới KingKoiFamJP",
    alt: "Shiro trại yamasanKF nhập khẩu bới KingKoiFamJP",
    data: "https://www.youtube.com/embed/ehBYExEkRn4",
    ratio: 1,
  },

  {
    id: "kJRjxEcrjC4",
    type: "video",
    title: "Cá nisai đấu giá của KINGKOIFARM JP",
    alt: "Cá nisai đấu giá của KINGKOIFARM JP",
    data: "https://www.youtube.com/embed/kJRjxEcrjC4",
    ratio: 1,
  },
  {
    id: "lkZ2mgftW3I",
    type: "video",
    title: "Cá nisai đấu giá của KINGKOIFARM JP",
    alt: "Cá nisai đấu giá của KINGKOIFARM JP",
    data: "https://www.youtube.com/embed/lkZ2mgftW3I",
    ratio: 1,
  },
  {
    id: "L0kl7HRyeaY",
    type: "video",
    title: "Cá nisai đấu giá của KINGKOIFARM JP",
    alt: "Cá nisai đấu giá của KINGKOIFARM JP",
    data: "https://www.youtube.com/embed/L0kl7HRyeaY",
    ratio: 1,
  },
  {
    id: "6AWgs65l_g4",
    type: "video",
    title: "Cá nisai đấu giá của KINGKOIFARM JP",
    alt: "Cá nisai đấu giá của KINGKOIFARM JP",
    data: "https://www.youtube.com/embed/6AWgs65l_g4",
    ratio: 1,
  },
  {
    id: "og7j1h1i15s",
    type: "video",
    title: "Cá nisai đấu giá của KINGKOIFARM JP",
    alt: "Cá nisai đấu giá của KINGKOIFARM JP",
    data: "https://www.youtube.com/embed/og7j1h1i15s",
    ratio: 1,
  },
]

const IndexPage: FC = () => {
  const { md, lg, xl, xxl } = Grid.useBreakpoint()

  return (
    <Layout>
      <SEO title="Home" />

      <StyledContainer fluid>
        <ResBanner
          src={MainSlider}
          ratio={9 / 16}
          xPosition="left"
          yPosition="center"
          bgColor="rgba(0,0,0,0.5)"
        >
          <Typography.Paragraph
            type="secondary"
            style={{
              color: "whitesmoke",
              textAlign: "left",
              width: "100%",
              maxWidth: "600px",
            }}
          >
            <strong>KING KOI FARM JP</strong> được thành lập năm 2018 với nhiều
            năm kinh nghiệm. Hiện nay chúng tôi đang cung cấp sỉ và lẻ hơn trăm
            loại cá Koi đa dạng về màu sắc, kích cỡ nhập khẩu từ Nhật Bản.
          </Typography.Paragraph>
        </ResBanner>
      </StyledContainer>

      <StyledContainer fluid bgColor="whitesmoke" padding="1rem">
        <StyledContainer bgColor="whitesmoke">
          <Typography.Title level={3}>SẢN PHẨM NỔI BẬT</Typography.Title>
          <ICSwiper
            swiperContents={BestSelling}
            slidesPerView={md || lg || xl || xxl ? 2.3 : 1.2}
          />
          <br />
          <Typography.Title level={3}>
            Liên Hệ Zalo: 0898947888 (mr Đạt)
          </Typography.Title>
        </StyledContainer>
      </StyledContainer>

      <StyledContainer fluid padding="1rem">
        <StyledContainer>
          <Typography.Title level={3} style={{ textAlign: "center" }}>
            ĐỐI TÁC NHẬT BẢN
          </Typography.Title>

          <JPBrands slidesPerView={lg || xl || xxl ? 5.2 : 2.2} />
        </StyledContainer>
      </StyledContainer>

      <StyledContainer fluid bgColor="whitesmoke" padding="1rem">
        <StyledContainer bgColor="whitesmoke">
          <Typography.Title level={3}>THI CÔNG, LẮP ĐẶT HỒ</Typography.Title>

          <Typography.Paragraph>
            Khu vườn thuỷ sinh trong nhà không những mang lại cảm giác gần gũi
            với thiên nhiên mà còn mang lại cho gia chủ sự thịnh vượng và suôn
            sẻ. Hãy liên hệ với chúng tôi ngay để được tư vấn lắp đặt.
          </Typography.Paragraph>

          <Typography.Paragraph>
            Nhận thiết kế thi công hồ cá Koi chuẩn Nhật. Chuyên cung cấp sỉ và
            lẻ các loại cá Koi chuẩn Nhật Bản. Chuyên cung cấp vật liệu lọc hồ
            và cám cá Koi. Chuyên thả cá công trình quán cà phê số lượng lớn.
          </Typography.Paragraph>

          <ResponsiveMasonry>
            <GridItem>
              <img
                width="100%"
                height="auto"
                src={IndoorPond}
                alt="vườn cá koi"
                title="vườn cá koi"
              />
            </GridItem>
            <GridItem>
              <img
                width="100%"
                height="auto"
                src={Pond1}
                alt="thuỷ sinh 1"
                title="thuỷ sinh 1"
              />
            </GridItem>
            <GridItem>
              <img
                width="100%"
                height="auto"
                src={Pond3}
                alt="thuỷ sinh 3"
                title="thuỷ sinh 3"
              />
            </GridItem>
            <GridItem>
              <img
                width="100%"
                height="auto"
                src={IndoorKoiGarden}
                alt="hồ cá trong nhà"
                title="hồ cá koi trong nhà"
              />
            </GridItem>
            <GridItem>
              <img
                width="100%"
                height="auto"
                src={Pond4}
                alt="thuỷ sinh 4"
                title="thuỷ sinh 4"
              />
            </GridItem>
            <GridItem>
              <img
                width="100%"
                height="auto"
                src={Pond2}
                alt="thuỷ sinh 5"
                title="thuỷ sinh 5"
              />
            </GridItem>
          </ResponsiveMasonry>
        </StyledContainer>
      </StyledContainer>

      <StyledContainer fluid>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7836.2747613560505!2d106.646469!3d10.877154!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d7502de8dbc3%3A0x106a5c28b8099b46!2sKING%20KOI%20FARM%20JP!5e0!3m2!1sen!2sus!4v1621208113987!5m2!1sen!2sus"
          width="100%"
          height="280"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          title="King Koi Farm JP bản đồ chỉ đường"
        />
      </StyledContainer>

      <StyledContainer padding="0.5rem" margin="1rem auto">
        <Typography.Title level={3} className="px-2">
          LIÊN HỆ KING KOI FARM JP
        </Typography.Title>

        <Row gutter={[24, 12]} justify="space-between">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <ContactForm />
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="px-2">
            <Typography.Title level={3}>GIỜ MỞ CỬA</Typography.Title>
            <Typography.Paragraph>
              THỨ 2 - CHỦ NHẬT: <strong>8:00 SÁNG - 9:00 TỐI</strong> <br />
            </Typography.Paragraph>

            <Typography.Title level={3}>THÔNG TIN LIÊN HỆ</Typography.Title>
            <Space direction="vertical">
              <Typography.Title level={5} type="secondary">
                Địa Chỉ: Hẻm 280 Thạnh Xuân 25, Quận 12 TPHCM
              </Typography.Title>
              <Button
                type="primary"
                shape="round"
                icon={<PhoneOutlined />}
                size="middle"
              >
                (089) 894-7888 (MR Đạt)
              </Button>
            </Space>
          </Col>
        </Row>
      </StyledContainer>
    </Layout>
  )
}

const JPBrands: FC<{ slidesPerView: number }> = ({ slidesPerView }) => (
  <Swiper
    slidesPerView={slidesPerView}
    autoplay={{ delay: 3500 }}
    spaceBetween={12}
    style={{ display: "flex", alignItems: "center" }}
  >
    {JapaneseBrands.map(jpBrand => (
      <SwiperSlide key={jpBrand.alt} style={{ padding: "12px" }}>
        <FlexContainer
          style={{ width: "120px", height: "120px" }}
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={jpBrand.src}
            alt={jpBrand.alt}
            width="120px"
            height="auto"
            style={{ margin: "auto 0" }}
          />
        </FlexContainer>
      </SwiperSlide>
    ))}
  </Swiper>
)

export default IndexPage
