import { FC } from "react"
import styled from "styled-components"

export interface ResBannerProps {
  src: string
  ratio: number
  opacity?: number
  xPosition?: "left" | "right" | "center"
  yPosition?: "top" | "bottom" | "center"
  bgColor?: string
}

interface BannerContainerProps {
  src: string
}
interface TextOverlayContainerWrapperProps {
  xPosition?: "left" | "right" | "center"
  yPosition?: "top" | "bottom" | "center"
  ratio: number
}
interface TextOverlayContainerProps {
  bgColor?: string
}

const BannerContainer = styled.div<BannerContainerProps>`
  width: 100%;
  background-image: url(${props => (props.src ? props.src : "")});
  /* Image is centered vertically and horizontally at all times */
  background-position: center center;
  /* Image doesn't repeat */
  background-repeat: no-repeat;
  /* This is what makes the background image rescale based on its container's size */
  background-size: cover;
  /* Pick a solid background color that will be displayed while the background image is loading */
  background-color: #464646;
`
const TextOverlayContainerWrapper = styled.div<TextOverlayContainerWrapperProps>`
  width: 100%;
  height: ${props => `${props.ratio * 100}vw`};
  padding: 1.5rem;
  display: flex;
  justify-content: ${props => {
    switch (props.xPosition) {
      case "left":
        return "start"
      case "right":
        return "end"
      default:
        return "center"
    }
  }};
  align-items: ${props => {
    switch (props.yPosition) {
      case "top":
        return "flex-start"
      case "bottom":
        return "flex-end"
      default:
        return "center"
    }
  }};
`
const TextOverlayContainer = styled.div<TextOverlayContainerProps>`
  background-color: ${props => props.bgColor || "none"};
  padding: 0.75rem;
  border-radius: 5px;
  text-align: center;
`

const ResBanner: FC<ResBannerProps> = ({
  src,
  ratio,
  xPosition,
  yPosition,
  bgColor,
  children,
}) => (
  <BannerContainer src={src}>
    <TextOverlayContainerWrapper
      xPosition={xPosition}
      yPosition={yPosition}
      ratio={ratio}
    >
      <TextOverlayContainer bgColor={bgColor}>{children}</TextOverlayContainer>
    </TextOverlayContainerWrapper>
  </BannerContainer>
)

export default ResBanner
