/* eslint-disable func-names */
import { FC } from "react"
import { Grid } from "antd"
import Masonry from "react-masonry-css"
import styled from "styled-components"

const MasonryWrapper = styled(Masonry)`
  &.my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
  }
  &.my-masonry-grid_column {
    background-clip: padding-box;
  }
`

const ResponsiveMasonry: FC = props => {
  const { md, lg, xl, xxl } = Grid.useBreakpoint()

  const breakPoints = function (): number {
    if (md || lg) return 3
    if (xl || xxl) return 5

    return 2
  }

  return (
    <MasonryWrapper
      breakpointCols={breakPoints()}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {props.children}
    </MasonryWrapper>
  )
}

export default ResponsiveMasonry
